function Logos(){
    return(
        <div className="sc__box sc__box--logos">
            <div className="logos d-flex justify-content-center">
                <div className="logos__it logos__it--1">
                    <img src={require("../assets/img/logo-1.svg").default}/>
                </div>
                <div className="logos__it logos__it--2">
                    <img src={require("../assets/img/logo-2.svg").default}/>
                </div>
            </div>
        </div>
    )
}

export default Logos;