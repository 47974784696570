import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
function Footer({screen, moveDown}) {
    const location = useLocation();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
    }, [location.pathname]);

    return (
        <>
            <div className={'footer' + (location.pathname === '/end' ? ' footer--thin' : '')}>
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.mars.com/privacy-policy-russian" target="_blank">Конфиденциальность</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/legal-russia" target="_blank">Юридические условия</a>
                            </li>
                            <li>
                                <a href="https://mars.com/" target="_blank">Владелец сайта</a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a href="/user-agreement.pdf" target="_blank">Пользовательское соглашение</a>
                            </li>
                            <li>
                                <a href="https://www.mars.com/cookies-russia" target="_blank">Cookies</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__rights text-left">
                        © 2024 Mars, Incorporated. Все права защищены
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
