import { Routes, Route } from 'react-router-dom'
import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react'
import {ProfileProvider} from "./context";
import Footer from './components/Footer'
import Home from './pages/Home'
import {Helmet} from "react-helmet";

const rollbarConfig = {
    accessToken: 'c6aed4b27341494fb4398aa37c783959',
    environment: 'testenv',
}

function App() {
  return (
      <>
          <Helmet>
              <title></title>
              <meta property="og:title" content=""/>
          </Helmet>
          <Provider config={rollbarConfig}>
              <ErrorBoundary>
                  <ProfileProvider>
                      <div id="wrapper" className="wrapper">
                          <Routes>
                              <Route path="/" element={<Home />} />
                          </Routes>
                          <Footer/>
                      </div>
                  </ProfileProvider>
              </ErrorBoundary>
          </Provider>
      </>
  );
}

export default App;
