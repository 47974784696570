import React, {createContext, useContext, useState, useMemo, useEffect} from 'react';
import useEffectOnce from "../hooks/useEffectOnce";
import axiosInstance from "../helpers/axios";
import {useRollbar} from "@rollbar/react";

const profileContextDefaultValues = {
    isCityLoading: true,
    city: {
        name: null,
        id: null,
        approved: false
    },
    geolocation: {
        lat: null,
        lon: null
    }
};

const ProfileContext = createContext({
    profileContextDefaultValues,
    setProfile: () => {}
});

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(profileContextDefaultValues);
    const rollbar = useRollbar();
    const value = useMemo(
        () => ({ profile, setProfile }),
        [profile]
    );

    useEffect(()=>{
        if(profile.city.name){
            localStorage.setItem('city', profile.city.name);
            localStorage.setItem('city_id', profile.city.id);
            localStorage.setItem('city_approved', 'true');
        }
    }, [profile]);

   useEffectOnce(()=>{
       initUser();
       if(localStorage.getItem('city')){
           setProfile({
               ...profile,
               city: {
                   ...profile.city,
                   id: localStorage.getItem('city_id'),
                   name: localStorage.getItem('city'),
                   approved: localStorage.getItem('city_approved') ? true : false
               },
               isCityLoading: false
           });
       }else{
           if ("geolocation" in navigator) {
               navigator.geolocation.getCurrentPosition((position) => {
                   console.log(position.coords.latitude);
                   getCityByLocation(position.coords.latitude, position.coords.longitude);
               }, function(error) {

                   rollbar.error('Error Get City By Location', error);

                   switch (error.code) {
                       case error.PERMISSION_DENIED:
                           console.log("User denied the request for geolocation.");
                           break;
                       case error.POSITION_UNAVAILABLE:
                           console.log("Location information is unavailable.");
                           break;
                       case error.TIMEOUT:
                           console.log("The request to get user location timed out.");
                           break;
                       case error.UNKNOWN_ERROR:
                           console.log("An unknown error occurred.");
                           break;
                   }

                   getCityByIp();
               });
           }else{
               console.log('No geolocation');
               getCityByIp();
           }
       }
   });

   const initUser = async() => {
       window.Telegram.WebApp.ready();
       window.Telegram.WebApp.expand();
       let initData = window.Telegram.WebApp.initData;
       if(!initData){
           rollbar.error('Error Get Telegram initData row');
           alert('Ошибка получения данных от Telegram. Пожалуйста, перезапустите приложение');
       }
       localStorage.setItem('auth_data', initData);
   }

    const resetProfileData = () => {
        setProfile(profileContextDefaultValues);
    }

    const getCityByLocation = async(lat, lon) => {
        try {
            const response = await axiosInstance.get('city-by-coords?lat=' + lat + '&lon=' + lon);
            if(response.data){
                if(response.data.result){
                    setProfile({
                        ...profile,
                        city: {
                            ...profile.city,
                            id: response.data.data.id,
                            name: response.data.data.name
                        },
                        isCityLoading: false,
                        geolocation: {
                            lat: lat,
                            lon: lon
                        }
                    });
                }else{
                    getCityByIp();
                }
            }
        } catch (error) {
            getCityByIp();
        }
    }

    const getCityByIp = async() => {
        try {
            const response = await axiosInstance.get('city-by-ip');
            if(response.data){
                if(response.data.result){
                    setProfile({
                        ...profile,
                        city: {
                            ...profile.city,
                            id: response.data.data.id,
                            name: response.data.data.name
                        },
                        isCityLoading: false
                    });
                }else {
                    setProfile({
                        ...profile,
                        city: {
                            ...profile.city,
                            id: 1,
                            name: 'Москва'
                        },
                        isCityLoading: false
                    });
                }
            }
        } catch (error) {
            rollbar.error('Error Get City By IP', error);
        }
    }

    return (
        <ProfileContext.Provider value={value}>
            {useMemo(() => (
                children
            ), [])}
        </ProfileContext.Provider>
    )
}

export function useProfile() {
  return useContext(ProfileContext)
}