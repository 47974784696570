import {useProfile} from "../context";
import {useEffect, useState} from "react";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import 'rc-slider/assets/index.css';
import ReCAPTCHA from "react-google-recaptcha";
import anime from 'animejs';
import Slider from 'rc-slider';
import Barcode from 'react-barcode';
import Countdown from 'react-countdown';
import parse from 'html-react-parser';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import { useRollbar } from '@rollbar/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import Logos from "../components/logos";

let anim_cards_tl1 = null;

const digitsAssets = {
    0: require("../assets/img/numbers/0.png"),
    1: require("../assets/img/numbers/1.png"),
    2: require("../assets/img/numbers/2.png"),
    3: require("../assets/img/numbers/3.png"),
    4: require("../assets/img/numbers/4.png"),
    5: require("../assets/img/numbers/5.png"),
    6: require("../assets/img/numbers/6.png"),
    7: require("../assets/img/numbers/7.png"),
    8: require("../assets/img/numbers/8.png"),
    9: require("../assets/img/numbers/9.png"),
}

const weatherAssets = {
    1: require("../assets/img/weather/1.svg").default,
    2: require("../assets/img/weather/2.svg").default,
    3: require("../assets/img/weather/3.svg").default,
    4: require("../assets/img/weather/4.svg").default,
    5: require("../assets/img/weather/5.svg").default,
    6: require("../assets/img/weather/6.svg").default,
    7: require("../assets/img/weather/7.svg").default,
    8: require("../assets/img/weather/8.svg").default,
    9: require("../assets/img/weather/9.svg").default,
    10: require("../assets/img/weather/10.svg").default,
    11: require("../assets/img/weather/11.svg").default
}

const trafficAssets = {
    1: require("../assets/img/traffic/1.svg").default,
    2: require("../assets/img/traffic/2.svg").default,
    3: require("../assets/img/traffic/3.svg").default,
}

const calendarAssets = {
    1: require("../assets/img/calendar/1.svg").default,
    2: require("../assets/img/calendar/2.svg").default,
    3: require("../assets/img/calendar/3.svg").default,
    4: require("../assets/img/calendar/4.svg").default,
}

const timeAssets = {
    1: require("../assets/img/time/1.svg").default,
    2: require("../assets/img/time/2.svg").default,
    3: require("../assets/img/time/3.svg").default,
    4: require("../assets/img/time/4.svg").default,
}

const animSpeed = 0.5;

const surveyData = {
    1: {
        title: 'Мой настрой',
        vars: [
            {
                title: 'Закипаю',
                id: 1,
                img: require("../assets/img/mood/1.svg").default
            },
            {
                title: 'Хочу на ручки',
                id: 2,
                img: require("../assets/img/mood/2.svg").default
            },
            {
                title: 'На расслабоне',
                id: 3,
                img: require("../assets/img/mood/3.svg").default
            },
            {
                title: 'Топовый',
                id: 4,
                img: require("../assets/img/mood/4.svg").default
            }
        ]
    },
    2: {
        title: 'Мой сон',
        vars: [
            {
                title: 'Какой сон?',
                id: 1,
                img: require("../assets/img/sleep/1.svg").default
            },
            {
                title: 'Немного залипаю',
                id: 2,
                img: require("../assets/img/sleep/2.svg").default
            },
            {
                title: 'Никогда не зеваю',
                id: 3,
                img: require("../assets/img/sleep/3.svg").default
            },
            {
                title: 'Разбудите, когда будет скидка',
                id: 4,
                img: require("../assets/img/sleep/4.svg").default
            }
        ]
    },
    3: {
        title: 'Моя локация',
        vars: [
            {
                title: 'На работе/учебе',
                id: 1,
                img: require("../assets/img/location/1.svg").default
            },
            {
                title: 'Другое',
                id: 2,
                img: require("../assets/img/location/2.svg").default
            },
            {
                title: 'В дороге',
                id: 3,
                img: require("../assets/img/location/3.svg").default
            },
            {
                title: 'Дома',
                id: 4,
                img: require("../assets/img/location/4.svg").default
            }
        ]
    },
    4: {
        title: 'Мой уровень голода',
        vars: [
            {
                title: 'Съем слона',
                id: 1,
                img: require("../assets/img/hunger/1.svg").default
            },
            {
                title: 'Немного голоден',
                id: 2,
                img: require("../assets/img/hunger/2.svg").default
            },
            {
                title: 'Сыт',
                id: 3,
                img: require("../assets/img/hunger/3.svg").default
            },
            {
                title: 'Мощно переел',
                id: 4,
                img: require("../assets/img/hunger/4.svg").default
            }
        ]
    }
};

const energyData = {
    1: {
        title: 'на нуле',
        img: require("../assets/img/speed-1.svg").default,
        text: 'Держи скидку и&nbsp;пополняй запас энергии!'
    },
    2: {
        title: 'на нуле',
        img: require("../assets/img/speed-2.svg").default,
        text: 'Чтобы повысить уровень энергии, держи скидку!'
    },
    3: {
        title: 'на максимум',
        img: require("../assets/img/speed-3.svg").default,
        text: 'Держи скидку и&nbsp;поддерживай ее&nbsp;на&nbsp;таком же&nbsp;крутом уровне.'
    }
}

const products = [
    {
        title: 'SNICKERS®, <br/>50,5&nbsp;г',
        img: require("../assets/img/products/1.png")
    },
    {
        title: 'SNICKERS® Super, <br/>80&nbsp;г',
        img: require("../assets/img/products/2.png")
    },
    {
        title: 'SNICKERS® Super <br/>Белый, 81&nbsp;г',
        img: require("../assets/img/products/3.png")
    },
    {
        title: 'SNICKERS® Super <br/>Лесной орех, 81&nbsp;г',
        img: require("../assets/img/products/4.png")
    },
    {
        title: 'SNICKERS® Криспер <br/>с рисовыми шариками, 60&nbsp;г',
        img: require("../assets/img/products/5.png")
    },
    {
        title: 'SNICKERS® Миндаль, <br/>81 г',
        img: require("../assets/img/products/6.png")
    },
    {
        title: 'SNICKERS® Миндаль, <br/>81 г',
        img: require("../assets/img/products/7.png")
    },
    {
        title: 'TWIX® Xtra, <br/>82 г',
        img: require("../assets/img/products/8.png")
    },
    {
        title: 'TWIX®, <br/>55 г',
        img: require("../assets/img/products/9.png")
    },
    {
        title: 'MILKY WAY® <br/>Клубничный коктейль, 26 г',
        img: require("../assets/img/products/10.png")
    },
    {
        title: 'MILKY WAY®, <br/>26 г',
        img: require("../assets/img/products/11.png")
    },
    {
        title: 'MILKY WAY® <br/>Клубничный коктейль, 26 г',
        img: require("../assets/img/products/12.png")
    },
    {
        title: 'MARS® Max, <br/>81 г',
        img: require("../assets/img/products/13.png")
    },
    {
        title: 'MARS®, <br/>50 г',
        img: require("../assets/img/products/14.png")
    },
    {
        title: 'BOUNTY® Trio, <br/>82,5 г',
        img: require("../assets/img/products/15.png")
    },
    {
        title: 'BOUNTY®, <br/>55 г',
        img: require("../assets/img/products/16.png")
    }
]

const declOfNum = function(number, titles)
{
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        (number % 100 > 4 && number % 100 < 20)
            ?
            2
            :
            cases[(number % 10 < 5) ? number % 10 : 5]
        ];
}

function Home() {
    const {profile, setProfile} = useProfile();
    const [isEnergyLoading, setIsEnergyLoading] = useState(false);
    const [isDiscountLoading, setIsDiscountLoading] = useState(false);
    const [isRemindLoading, setIsRemindLoading] = useState(false);
    const [isSurveyLoading, setIsSurveyLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState('');
    const [discount, setDiscount] = useState(null);
    const [copied, setCopied] = useState(false);
    const [preCaptcha, setPreCaptcha] = useState(null);
    const [cityStatus, setCityStatus] = useState({
        energy: 0,
        discount: 0
    });
    const rollbar = useRollbar();

    const [limits, setLimits] = useState({
        error: null,
        time: 0
    })

    const [cityData, setCityData] = useState({
        name: null,
        id: null,
        approved: false
    });
    const [screen, setScreen] = useState('main');
    const [surveyStep, setSurveyStep] = useState(0);
    const [surveyVar, setSurveyVar] = useState(1);
    const [answers, setAnswers] = useState({
        1: 1,
        2: 1,
        3: 1,
        4: 1
    });

    const [energyLevel, setEnergyLevel] = useState(1);

    useEffectOnce(()=>{
        getCities();
    });

    useEffect(()=>{
        if(profile.city.approved && profile.city.id){
            getEnergy();
        }
    }, [profile.city]);

    useEffect(()=>{
        if(limits.error){
            setScreen('limits');
        }
    }, [limits]);

    useEffect(()=>{
        if(preCaptcha){
            setScreen('captcha');
        }
    }, [preCaptcha]);

    useEffect(()=>{
        if(cityStatus.energy){
            animCards();
            if(cityStatus.energy < 34) {
                setEnergyLevel(1);
            }

            if(cityStatus.energy >= 34 && cityStatus.energy < 66) {
                setEnergyLevel(2);
            }

            if(cityStatus.energy >= 66) {
                setEnergyLevel(3);
            }
        }
    }, [cityStatus]);

    useEffect(()=>{
        if(discount){
            setScreen('discount');
        }
    }, [discount]);

    const getEnergy = async() => {
        try {
            setIsEnergyLoading(true);
            const response = await axiosInstance.get('energy?city_id=' + profile.city.id);
            if(response.data){
                if(response.data.result){
                    setCityStatus(response.data.data);
                    setIsEnergyLoading(false);
                }
            }
        } catch (error) {
            rollbar.error('Error Get Energy', error);
            setIsEnergyLoading(false);
        }
    }

    const getCities = async() => {
        try {
            const response = await axiosInstance.get('cities');
            if(response.data){
                if(response.data.result){
                    setCities(response.data.data);
                }
            }
        } catch (error) {
            rollbar.error('Error Get Cities list', error);
        }
    }

    const sendSurvey = async() => {
        setIsSurveyLoading(true);
        try {
            const formData = new FormData();
            formData.append('city_id', profile.city.id);
            if(surveyStep === 4){
                formData.append('answers[]', answers[1]);
                formData.append('answers[]', answers[2]);
                formData.append('answers[]', answers[3]);
                formData.append('answers[]', answers[4]);
            }

            const response = await axiosInstance.post('survey', formData);
            if(response.data){
                if(response.data.result){
                    setCityStatus(response.data.data);
                    setIsSurveyLoading(false);
                    setScreen('result')
                }else{
                    setIsSurveyLoading(false);
                }
            }
        } catch (error) {
            setIsSurveyLoading(false);
            rollbar.error('Error Get Survey', error);
        }
    }

    const remind = async() => {
        setIsRemindLoading(true);
        try {
            const response = await axiosInstance.post('remind');
            if(response.data){
                setIsRemindLoading(false);
                setScreen('result');
            }
        } catch (error) {
            setIsRemindLoading(false);
            rollbar.error('Error Send Remind', error);
        }
    }

    const getDiscount = async(type, captcha = null) => {
        setIsDiscountLoading(true);
        try {
            const formData = new FormData();
            formData.append('city_id', profile.city.id);
            formData.append('type', type);
            if(captcha){
                formData.append('g-recaptcha-response', captcha);
            }
            if(surveyStep === 4){
                formData.append('answers[]', answers[1]);
                formData.append('answers[]', answers[2]);
                formData.append('answers[]', answers[3]);
                formData.append('answers[]', answers[4]);
            }

            const response = await axiosInstance.post('discount', formData);
            if(response.data){
                if(response.data.result){
                    setDiscount({
                        ...response.data.data,
                        type: type
                    });
                    setIsDiscountLoading(false);
                }else{
                    setIsDiscountLoading(false);
                    if(response.data.error && response.data.error.minutes){
                        setLimits({
                            error: response.data.error.text,
                            time: response.data.error.minutes,
                        })
                    }else{
                        setLimits({
                            error: response.data.error
                        })
                    }

                }
            }
        } catch (error) {
            setIsDiscountLoading(false);
            rollbar.error('Error Get Discount', error);
        }
    }

    const animCards = () => {
        anim_cards_tl1 = anime.timeline();
        anim_cards_tl1.add({
            targets: '#card--1',
            opacity: [0, 1],
            translateY: ['-100%', '-0.6rem'],
            duration: 550 * animSpeed,
            easing: 'easeOutCubic',
            complete: function(anim) {
                anime({
                    targets: document.querySelector('#calc-value'),
                    innerHTML: [0, cityStatus.weather.discount],
                    easing: 'linear',
                    round: 1
                });

                anime({
                    targets: document.querySelector('#calc-arrow'),
                    rotate: 20 + cityStatus.weather.discount * 140 / 100 + 'deg',
                    easing: 'spring(4, 80, 10, 0)'
                });
            }
        }).add({
            targets: '.calc__cards',
            translateY: [0, '16.5rem'],
            duration: 550 * animSpeed,
            easing: 'spring(4, 80, 10, 0)'
        }, '-=' + 200 * animSpeed).add({
            targets: '#card--2',
            opacity: [0, 1],
            translateY: ['-200%', 0],
            duration: 550 * animSpeed,
            easing: 'easeOutCubic',
            complete: function(anim) {
                anime({
                    targets: document.querySelector('#calc-value'),
                    innerHTML: cityStatus.traffic.discount,
                    easing: 'linear',
                    round: 1
                });

                anime({
                    targets: document.querySelector('#calc-arrow'),
                    rotate: 20 + cityStatus.traffic.discount * 140 / 100 + 'deg',
                    easing: 'spring(4, 80, 10, 0)'
                });
            }
        }).add({
            targets: '.calc__cards',
            translateY: '26.5rem',
            duration: 700 * animSpeed,
            easing: 'cubicBezier(.5, .05, .1, .3)'
        }, '-=200').add({
            targets: '.calc__cards',
            translateY: '16.5rem',
            duration: 250 * animSpeed,
            easing: 'spring(4, 80, 10, 0)'
        }, '-=' + 100 * animSpeed).add({
            targets: '#card--3',
            opacity: [0, 1],
            translateY: ['-200%', 0],
            duration: 550 * animSpeed,
            easing: 'easeOutCubic',
            complete: function(anim) {
                anime({
                    targets: document.querySelector('#calc-value'),
                    innerHTML: cityStatus.calendar.discount,
                    easing: 'linear',
                    round: 1
                });

                anime({
                    targets: document.querySelector('#calc-arrow'),
                    rotate: 20 + cityStatus.calendar.discount * 140 / 100 + 'deg',
                    easing: 'spring(4, 80, 10, 0)'
                });
            }
        }).add({
            targets: '.calc__cards',
            translateY: '26.5rem',
            duration: 700 * animSpeed,
            easing: 'cubicBezier(.5, .05, .1, .3)'
        }, '-=' + 200 * animSpeed).add({
            targets: '.calc__cards',
            translateY: '16.5rem',
            duration: 250 * animSpeed,
            easing: 'spring(4, 80, 10, 0)'
        }, '-=' + 100 * animSpeed).add({
            targets: '#card--4',
            opacity: [0, 1],
            translateY: ['-200%', 0],
            duration: 550 * animSpeed,
            easing: 'easeOutCubic',
            complete: function(anim) {
                anime({
                    targets: document.querySelector('#calc-value'),
                    innerHTML: cityStatus.time.discount,
                    easing: 'linear',
                    round: 1
                });

                anime({
                    targets: document.querySelector('#calc-arrow'),
                    rotate: 20 + cityStatus.time.discount * 140 / 100 + 'deg',
                    easing: 'spring(4, 80, 10, 0)'
                });
            }
        }).add({
            targets: '.calc__cards',
            translateY: '26.5rem',
            duration: 700 * animSpeed,
            easing: 'cubicBezier(.5, .05, .1, .3)'
        }, '-=' + 200 * animSpeed).add({
            targets: '.calc__cards',
            translateY: '16.5rem',
            duration: 250 * animSpeed,
            easing: 'spring(4, 80, 10, 0)',
            complete: function(anim) {
                setScreen('result');
            }
        }, '-=' + 100 * animSpeed);
    }

    const renderer = ({ hours, minutes, completed }) => {
        return(
            <div className="counter__list d-flex justify-content-center">
                <div className="counter__it">
                    <div className="counter__it-bl-ct">
                        <div className="counter__it-values d-flex justify-content-center">
                            <div className="counter__it-value">
                                <img src={digitsAssets[('0' + hours).slice(-2)[0]]}/>
                            </div>
                            <div className="counter__it-value">
                                <img src={digitsAssets[('0' + hours).slice(-2)[1]]}/>
                            </div>
                        </div>
                        <div className="counter__it-hint">
                            {declOfNum(hours, ['час', 'часа', 'часов'])}
                        </div>
                    </div>
                </div>
                <div className="counter__it">
                    <div className="counter__it-bl d-flex align-items-center justify-content-center">
                        <div className="counter__it-bl-ct">
                            <div className="counter__it-values d-flex justify-content-center">
                                <div className="counter__it-value">
                                    <img src={digitsAssets[('0' + minutes).slice(-2)[0]]}/>
                                </div>
                                <div className="counter__it-value">
                                    <img src={digitsAssets[('0' + minutes).slice(-2)[1]]}/>
                                </div>
                            </div>
                            <div className="counter__it-hint">
                                {declOfNum(minutes, ['минута', 'минуты', 'минут'])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return(
        <>
            {screen === 'main' && (
                <div className="sc__full fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <Logos/>
                        {profile.isCityLoading && (
                            <div className="w-100 d-flex justify-content-center align-items-center fadeIn animated">
                                <div className="loader__inline"/>
                            </div>
                        )}

                        {!profile.isCityLoading && !profile.city.approved && (
                            <>
                                <div className="sc__box text-center fadeIn animated">
                                    <div className="promo__subtitle">
                                        Мало энергии?
                                    </div>
                                    <div className="promo__title promo__title--center">
                                        Зарядись
                                    </div>
                                    <div className="promo__title">
                                        по скидке
                                    </div>
                                    <div className="promo__info-box d-flex justify-content-center align-items-center">
                                        <div className="promo__info-img">
                                            <img src={require("../assets/img/decor-battery.png")}/>
                                        </div>
                                        <div className="promo__info-descr">
                                            Укажи свой город, чтобы <br/>
                                            узнать уровень энергии <br/>
                                            и скидку на Snickers® <br/>
                                            и другие батончики
                                        </div>
                                    </div>
                                </div>
                                <div className="sc__box fadeIn animated">
                                    <div className="location__box d-flex justify-content-center align-items-center">
                                        <div className="location__icon">
                                            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M29.7984 21.607C31.0907 19.7604 31.8491 17.5125 31.8491 15.0876C31.8491 8.80078 26.7525 3.70435 20.4658 3.70435C14.179 3.70435 9.08252 8.80078 9.08252 15.0876C9.08252 17.5127 9.84084 19.7606 11.1333 21.6072L19.6059 35.8807C19.9933 36.5334 20.9382 36.5334 21.3257 35.8807L29.7984 21.607Z" fill="#200082"/>
                                                <circle cx="20.4653" cy="15.6418" r="4.875" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className="location__title">
                                            Ты находишься в городе {profile.city.name}?
                                        </div>
                                    </div>
                                    <div className="location__actions">
                                        <div className="row">
                                            <div className="col col-6">
                                                <div onClick={()=>{
                                                    setProfile({
                                                        ...profile,
                                                        city: {
                                                            ...profile.city,
                                                            approved: true
                                                        }
                                                    });
                                                }} className="btn btn--filled d-flex align-items-center justify-content-center">
                                                    Да
                                                </div>
                                            </div>
                                            <div className="col col-6">
                                                <div onClick={()=>{
                                                    setScreen('city');
                                                }} className="btn btn--border d-flex align-items-center justify-content-center">
                                                    Нет, другой город
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        )}

                        {!profile.isCityLoading && profile.city.approved && (
                            <div className="sc__box sc__box--calc fadeIn animated">
                                <div className="sc__text text-center">
                                    Алгоритм мониторит факторы, которые <br/>
                                    влияют на уровень твоей энергии
                                </div>
                                <div className="calc__scale d-flex align-items-center justify-content-between">
                                    <div className="calc__scale-left">
                                        <div className="calc__scale-text">
                                            Скидка <br/>
                                            вычисляется
                                        </div>
                                        <div className="calc__scale-value">
                                            <span id="calc-value">0</span>%
                                        </div>
                                    </div>
                                    <div className="calc__scale-right">
                                        <img src={require("../assets/img/calc-scale.png")}/>

                                        <div id="calc-arrow" className="calc__scale-arrow">
                                            <img src={require("../assets/img/calc-arrow.svg").default}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="calc__cards">
                                    <div className="calc__cards-base"/>
                                    {cityStatus.weather && (
                                        <div className="cards__box">
                                            <div id="card--1" className="cards__it">
                                                <div className="cards__it-text">
                                                    В городе {profile.city.name} сейчас <br/>
                                                    преимущественно
                                                </div>
                                                <div className="cards__it-icon">
                                                    <div className="cards__it-icon-wrap">
                                                        <img src={weatherAssets[cityStatus.weather.type]}/>
                                                    </div>
                                                </div>
                                                <div className="cards__it-text">
                                                    {cityStatus.weather.title}
                                                </div>
                                            </div>
                                            <div id="card--2" className="cards__it">
                                                <div className="cards__it-text">
                                                    В городе {profile.city.name} сейчас <br/>
                                                    преимущественно
                                                </div>
                                                <div className="cards__it-icon">
                                                    <div className="cards__it-icon-wrap">
                                                        <img src={trafficAssets[cityStatus.traffic.type]}/>
                                                    </div>
                                                </div>
                                                <div className="cards__it-text">
                                                    {cityStatus.traffic.title}
                                                </div>
                                            </div>
                                            <div id="card--3" className="cards__it">
                                                <div className="cards__it-text">
                                                    В городе {profile.city.name} сейчас
                                                </div>
                                                <div className="cards__it-icon">
                                                    <div className="cards__it-icon-wrap">
                                                        <img src={calendarAssets[cityStatus.calendar.type]}/>
                                                    </div>
                                                </div>
                                                <div className="cards__it-text">
                                                    {cityStatus.calendar.title}
                                                </div>
                                            </div>
                                            <div id="card--4" className="cards__it">
                                                <div className="cards__it-text">
                                                    В городе {profile.city.name} сейчас
                                                </div>
                                                <div className="cards__it-icon">
                                                    <div className="cards__it-icon-wrap">
                                                        <img src={timeAssets[cityStatus.time.type]}/>
                                                    </div>
                                                </div>
                                                <div className="cards__it-text">
                                                    {cityStatus.time.title}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="bottom__actions">
                                    <button disabled={isEnergyLoading} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center"
                                            onClick={()=>{
                                                setSurveyStep(0);
                                                setSurveyVar(1);
                                                setScreen('survey');
                                                if(anim_cards_tl1) {
                                                    anim_cards_tl1.pause();
                                                }

                                            }}
                                    >
                                        добавить персональную скидку
                                    </button>
                                    <button onClick={()=>{
                                        setCityData({
                                            name: null,
                                            id: null,
                                            approved: false
                                        });
                                        if(anim_cards_tl1) {
                                            anim_cards_tl1.pause();
                                        }
                                        setCity('');
                                        setScreen('city');
                                    }} className="btn-ct btn-ct--transparent d-flex align-items-center justify-content-center">
                                        изменить город
                                    </button>
                                </div>
                            </div>
                        )}

                        {!profile.city.approved && (
                            <div className="sc__box">
                                <div className="sc__box-bottom">
                                    <img src={require("../assets/img/decor-img-bottom.png")}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {screen === 'survey' && (
                <div className="sc__full sc__full--survey fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <Logos/>

                        {surveyStep === 0 && (
                            <>
                                <div className="sc__box fadeIn animated">
                                    <div className="sc__text text-center">
                                        Пройди мини-тест, чтобы узнать свой <br/>
                                        уровень энергии и персонализировать <br/>
                                        скидку
                                    </div>
                                </div>
                                <div className="sc__box fadeIn animated">
                                    <div className="survey__promo">
                                        <div className="survey__promo-it d-flex align-items-center justify-content-center">
                                            <div className="survey__promo-it-ct">
                                                <div className="survey__promo-it-icon">
                                                    <img src={require("../assets/img/s-i-1.png")}/>
                                                </div>
                                                <div className="survey__promo-it-title">
                                                    Сон
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey__promo-it d-flex align-items-center justify-content-center">
                                            <div className="survey__promo-it-ct">
                                                <div className="survey__promo-it-icon">
                                                    <img src={require("../assets/img/s-i-2.png")}/>
                                                </div>
                                                <div className="survey__promo-it-title">
                                                    Настрой
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey__promo-it d-flex align-items-center justify-content-center">
                                            <div className="survey__promo-it-ct">
                                                <div className="survey__promo-it-icon">
                                                    <img src={require("../assets/img/s-i-3.png")}/>
                                                </div>
                                                <div className="survey__promo-it-title">
                                                    Локация
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey__promo-it d-flex align-items-center justify-content-center">
                                            <div className="survey__promo-it-ct">
                                                <div className="survey__promo-it-icon">
                                                    <img src={require("../assets/img/s-i-4.png")}/>
                                                </div>
                                                <div className="survey__promo-it-title">
                                                    Голод
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {surveyStep > 0 && (
                            <div className="sc__box fadeIn animated">
                                <div className="survey__grid">
                                    <div className="survey__header d-flex justify-content-center">
                                        <div className="survey__title text-center">
                                            {surveyData[surveyStep].title}
                                        </div>
                                        <div onClick={()=>{
                                            setSurveyStep(surveyStep > 0 ? surveyStep - 1 : 0);
                                        }} className="survey__close">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.83984 1.79688L12.7598 12.7169" stroke="white" strokeWidth="1.92" strokeLinecap="round"/>
                                                <path d="M12.7598 1.79688L1.83977 12.7169" stroke="white" strokeWidth="1.92" strokeLinecap="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="survey__icon">
                                        <img src={surveyData[surveyStep].vars[surveyVar - 1].img}/>
                                    </div>
                                    <div className="survey__slider">
                                        <Slider
                                            min={1}
                                            max={4}
                                            value={surveyVar}
                                            onChange={(value)=>{
                                                setSurveyVar(value);
                                            }}
                                            marks={{ 1: 1, 2: 2, 3: 3, 4: 4 }}
                                        />
                                    </div>

                                    <div className="survey__title text-center">
                                        {surveyData[surveyStep].vars[surveyVar - 1].title}
                                    </div>
                                    <div className="survey__hint text-center">
                                        Двигай ползунок, <br/>
                                        чтобы оценить свой настрой, и нажимай <br/>
                                        «Далее»
                                    </div>
                                </div>

                            </div>
                        )}

                        <div className="sc__box">
                            <div className={'bottom__actions action__box' + (isSurveyLoading ? ' loading' : '')}>
                                <button disabled={isSurveyLoading} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center"
                                    onClick={()=>{
                                        setAnswers({
                                            ...answers,
                                            [surveyStep]: surveyVar
                                        });

                                        if(surveyStep === 4) {
                                            sendSurvey()
                                        }else {
                                            setSurveyVar(1);
                                            setSurveyStep(surveyStep < 4 ? surveyStep + 1 : 4);
                                        }
                                    }}
                                >
                                    <span>{surveyStep === 0 ? 'Начать' : 'Далее'}</span>
                                </button>
                                <button disabled={isSurveyLoading} onClick={()=>{
                                    if(surveyStep === 0) {
                                        setScreen('result');
                                    }else {
                                        setSurveyStep(surveyStep > 0 ? surveyStep - 1 : 0);
                                    }
                                }} className="btn-ct btn-ct--transparent d-flex align-items-center justify-content-center">
                                    назад
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {screen === 'city' && (
                <div className="sc__full sc__city fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <div className="location__box d-flex align-items-center">
                            <div className="location__icon">
                                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M29.7984 21.607C31.0907 19.7604 31.8491 17.5125 31.8491 15.0876C31.8491 8.80078 26.7525 3.70435 20.4658 3.70435C14.179 3.70435 9.08252 8.80078 9.08252 15.0876C9.08252 17.5127 9.84084 19.7606 11.1333 21.6072L19.6059 35.8807C19.9933 36.5334 20.9382 36.5334 21.3257 35.8807L29.7984 21.607Z" fill="#200082"/>
                                    <circle cx="20.4653" cy="15.6418" r="4.875" fill="white"/>
                                </svg>
                            </div>
                            <div className="location__title">
                                Начни вводить свой город:
                            </div>
                        </div>
                        <div className="location__field">
                            <input
                                type="text"
                                placeholder="Поиск"
                                value={city}
                                onChange={(e)=>{
                                    setCity(e.target.value);
                                    setCityData({
                                        name: null,
                                        id: null,
                                        approved: false
                                    });
                                }}
                            />
                            {city.length > 0 && (
                                <div onClick={()=>{
                                    setCity('');
                                    setCityData({
                                        name: null,
                                        id: null,
                                        approved: false
                                    });
                                }} className="location__clear">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="9.32178" cy="9.25647" r="8" stroke="white"/>
                                        <path d="M6.66376 6.56897L12.0093 11.944M6.63428 11.944L11.9798 6.56897" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div className="location__dropdown-box">
                            <div className={'location__dropdown-wrap fadeIn animated' + ((cityData.name === null) ? ' opened' : '')}>
                                <div className="location__dropdown-scroll">
                                    <div className="location__dropdown">
                                        {cities.filter((it) => it.name.toLowerCase().includes(city.toLowerCase())).map((cityIt, i) => (
                                            <div onClick={()=>{
                                                setCity(cityIt.name);
                                                setCityData({
                                                    ...cityData,
                                                    name: cityIt.name,
                                                    id: cityIt.id,
                                                });
                                            }} className="location__dropdown-it">
                                                {cityIt.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="bottom__actions">
                            <button disabled={!cityData.name}
                                    className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center"
                                    onClick={()=>{
                                        setProfile({
                                            ...profile,
                                            city: {
                                                name: cityData.name,
                                                id: cityData.id,
                                                approved: true
                                            }
                                        });
                                        setScreen('main');
                                        setCity('');
                                        setCityData({
                                            name: null,
                                            id: null,
                                            approved: false
                                        });
                                    }}
                            >
                                Далее
                            </button>
                            <button onClick={()=>{
                                setScreen('main');
                            }} className="btn-ct btn-ct--blue d-flex align-items-center justify-content-center">
                                <i className="icon__back">
                                    <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 2.26465L2 9.76465L7 17.2646" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                    </svg>
                                </i>
                                <span>Назад</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {screen === 'result' && (
                <div className="sc__full sc__full--result fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <Logos/>

                        <div className="sc__box">
                            <div className="text-center u-mb--1 u-color--semi">
                                Алгоритм мониторит факторы, которые <br/>
                                влияют на уровень твоей энергии
                            </div>
                            <div className="result__wrap">
                                <div className="result__card u-mb--1">
                                    <div className="result__card-bl">
                                        <div className="result__card-header">
                                            <div className="result__card-subtitle text-center">
                                                Твоя энергия сейчас
                                            </div>
                                            <div className="result__card-title text-center" data-level={energyLevel}>
                                                {parse(energyData[energyLevel].title)}
                                            </div>
                                            <div className="result__speed">
                                                <img src={energyData[energyLevel].img}/>
                                            </div>
                                        </div>
                                        <div className="text-center u-color--white">
                                            {parse(energyData[energyLevel].text)}
                                        </div>
                                    </div>
                                </div>
                                <div className="result__energy d-flex align-items-center justify-content-center">
                                    <span>Твоя скидка: <span className="result__energy-value">{cityStatus.discount}</span>%</span>
                                </div>
                            </div>

                        </div>

                        <div className="sc__box">
                            <div className="text-center u-mb--2 u-color--white">
                                Выбери, где использовать скидку
                            </div>
                            <div className={'row action__box' + (isDiscountLoading ? ' loading' : '')}>
                                <div className="col col-6">
                                    <button disabled={isDiscountLoading} onClick={()=>{
                                        setPreCaptcha(1);
                                    }} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center">
                                        в магазине
                                    </button>
                                </div>
                                <div className="col col-6">
                                    <button onClick={()=>{
                                        setPreCaptcha(2);
                                    }} disabled={isDiscountLoading} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center">
                                        в приложении
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="sc__box">
                            <div className="bottom__actions bottom__actions--wide">
                                <button className="btn-ct btn-ct--blue d-flex align-items-center justify-content-center"
                                        onClick={()=>{
                                            setSurveyStep(0);
                                            setSurveyVar(1);
                                            setScreen('survey');
                                        }}
                                >
                                    <span>{surveyStep === 4 ? 'пройти тест заново' : 'добавить персональную скидку'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {screen === 'discount' && discount && (
                <div className="sc__full sc__full--discount fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <Logos/>
                        <div className="sc__box">
                            <div className="discount__box">
                                <div className="discount__box-bl">
                                    <div className="discount__title text-center">
                                        Твоя скидка {discount.discount}%
                                    </div>
                                    {discount.type == 1 && (
                                        <>
                                            <div className="discount__subtitle text-center">
                                                Покажи штрихкод на&nbsp;кассе и&nbsp;зарядись
                                            </div>
                                            <div className="discount__code d-flex align-items-center">
                                                <Barcode
                                                    value={discount.code}
                                                    displayValue={true}
                                                />
                                            </div>
                                            <div className="discount__descr text-center">
                                                Скидка по&nbsp;купону распространяется на&nbsp;1&nbsp;батончик в&nbsp;течение 24&nbsp;часов с&nbsp;момента получения купона.
                                            </div>
                                            <div className="discount__descr text-center">
                                                Скидка не&nbsp;действует на&nbsp;желтые ценники и&nbsp;не&nbsp;суммируется с&nbsp;другими скидками, предоставляемыми на&nbsp;кассе. С&nbsp;информацией об&nbsp;акционной продукции и&nbsp;магазинах, участвующих в&nbsp;акции, можно ознакомиться в&nbsp;<a href="/rules.pdf">Правилах Акции</a>.
                                            </div>
                                        </>
                                    )}
                                    {discount.type == 2 && (
                                        <>
                                            <div className="discount__subtitle text-center">
                                                При оформлении заказа от&nbsp;1200&nbsp;руб.&nbsp;в&nbsp;приложении &laquo;Магнит Акции и&nbsp;Доставка&raquo;. Введи указанный код в&nbsp;корзине в&nbsp;поле &laquo;Промокод&raquo;.
                                            </div>
                                            <div className="discount__app-code">
                                                <div className="discount__app-code-value text-center">
                                                    {discount.code}
                                                </div>
                                                <div className="discount__app-code-link d-flex justify-content-center">
                                                    <CopyToClipboard text={discount.code}
                                                                     onCopy={() => {
                                                                         setCopied(true);
                                                                         setTimeout(function (){
                                                                             setCopied(false);
                                                                         }, 2000);
                                                                     }}
                                                    >
                                                        <div className="copy__link">
                                                            скопировать
                                                        </div>
                                                    </CopyToClipboard>
                                                </div>

                                                {copied && (
                                                    <div className="copy__status">
                                                        Скопировано
                                                    </div>
                                                )}
                                            </div>

                                            <div className="discount__descr text-center">
                                                Скидка распространяется на&nbsp;1&nbsp;батончик и&nbsp;действует 24&nbsp;часа.
                                            </div>
                                            <div className="discount__descr text-center">
                                                С&nbsp;информацией об&nbsp;акционной продукции и&nbsp;магазинах, участвующих в&nbsp;акции, можно ознакомиться в&nbsp;<a href="/rules.pdf">Правилах Акции</a>.
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="sc__box">
                            <div className="sc__products-title">
                                Продукты, на которые действует <br/>
                                скидка
                            </div>
                            <div className="products__wrap">
                                <div className="products__sl-box">
                                    <Swiper
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        scrollbar
                                        slidesPerView={2}
                                        modules={[Scrollbar]}
                                    >
                                        {products.map((pr, i) => (
                                            <SwiperSlide>
                                                <div className="products__it">
                                                    <div className="products__it-img">
                                                        <img src={pr.img}/>
                                                    </div>
                                                    <div className="products__it-title">
                                                        {parse(pr.title)}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div className={'sc__box d-flex justify-content-between action__box' + (isDiscountLoading ? ' loading' : '')}>
                            <button onClick={()=>{
                                window.location.reload();
                            }} disabled={isDiscountLoading} className="btn-ct btn-ct--icon btn-ct--blue d-flex align-items-center justify-content-center">
                                <img src={require("../assets/img/icon-home.svg").default}/>
                            </button>
                            <button onClick={()=>{
                                window.location.reload();
                            }} disabled={isDiscountLoading} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center">
                                Получить новую скидку
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {screen === 'limits' && limits.error && (
                <div className="sc__full sc__full--limits fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <div className="sc__box">
                            <Logos/>
                            <div className="discount__box">
                                <div className="discount__box-bl">
                                    {limits.time && (
                                        <>
                                            <div className="discount__title text-center">
                                                Упс, купоны на&nbsp;скидку закончились
                                            </div>
                                            <div className="discount__subtitle text-center">
                                                Приходи за&nbsp;новыми через:
                                            </div>
                                            <div className="counter__box" data-time={limits.time}>
                                                <Countdown
                                                    date={Date.now() + limits.time * 60 * 1000}
                                                    renderer={renderer}
                                                    onComplete={()=>{
                                                        window.location.reload();
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}

                                    {!limits.time && (
                                        <div className="discount__title text-center">
                                            {limits.error}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="sc__box">
                            <div className={"bottom__actions action__box" + (isRemindLoading ? ' loading' : '')}>
                                {limits.time && (
                                    <button disabled={isRemindLoading} onClick={()=>{
                                        remind();
                                    }} className="btn-ct btn-ct--orange d-flex align-items-center justify-content-center">
                                        Отправить напоминание
                                    </button>
                                )}
                                {!limits.time && (
                                    <div onClick={()=>{
                                        setScreen('result');
                                    }} className="btn-ct btn-ct--transparent d-flex align-items-center justify-content-center">
                                        назад
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {screen === 'captcha' && (
                <div className="sc__full sc__full--captcha fadeIn animated">
                    <div className="container d-flex flex-column justify-content-between">
                        <div className="sc__box">
                            <Logos/>
                        </div>
                        <div className={'sc__box d-flex justify-content-center action__box' + (isDiscountLoading ? ' loading' : '')}>
                            <div className="sc__box-captcha">
                                <ReCAPTCHA
                                    sitekey="6LccBE0qAAAAAI8dW71Cr47QAOYJVLbjQ0wOgP0g"
                                    onChange={(val) => {
                                        getDiscount(preCaptcha, val);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="sc__box">
                            <button disabled={isDiscountLoading} onClick={()=>{
                                setScreen('discount');
                            }} className="btn-ct btn-ct--blue d-flex align-items-center justify-content-center">
                                <i className="icon__back">
                                    <svg width="9" height="19" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 2.26465L2 9.76465L7 17.2646" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                                    </svg>
                                </i>
                                <span>назад</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Home;